<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container
        ref="pageContainer"
        class="address-list-container nav-page-container"
        :scroll-top.sync="scrollTop"
    >
      <!-- 下拉刷新 -->
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <div
              v-for="item of dataList"
              class="address-item"
              @click="handleAddressClick(item)"
          >
            <div class="address-info-container">
              <div class="user-info-container">
                <div class="user-info">
                  {{ item.name }} <span>{{ item.mobile }}</span>
                </div>
                <div
                    v-if="isDefaultAddress(item)"
                    class="default-tag"
                >
                  <van-tag plain type="primary">默认</van-tag>
                </div>
              </div>
              <div class="user-address-info">
                {{ formatAddressText(item) }}
              </div>
              <div
                  class="edit-btn"
                  @click.stop="edit(item)"
              >
                <van-icon name="edit"/>
              </div>
            </div>

            <div class="bottom-btn-container">
              <div
                  :class="{'is-default': isDefaultAddress(item)}"
                  @click.stop="setDefault(item)"
              >
                <van-icon
                    :name="isDefaultAddress(item) ? 'checked' : 'circle'"
                />
                默认地址
              </div>

              <div class="del-btn" @click.stop="del(item)">删除</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

      <div class="add-btn-container">
        <van-button
            icon="plus"
            round
            type="primary"
            block
            @click="add"
        >
          添加收货地址
        </van-button>
      </div>
    </page-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog } from 'vant';
import { queryUserAddressByPage, editDefaultAddress, deleteAddress } from '@/api/address';

export default {
  name: 'addressList',
  data() {
    return {
      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      deleting: false,
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.receiverAddress.refreshing,
    }),
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('receiverAddress/setRefreshing', false);
      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        // searchData,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return queryUserAddressByPage({
        // ...searchData,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    // 设置默认地址
    setDefault(item) {
      if (this.isDefaultAddress(item))
        return;
      editDefaultAddress({ id: item.id }).then(res => {
        if (res === true) {
          this.$store.commit('payment/setAddressId');
          this.dataList.forEach(e => {
            e.defaultAddress = e.id === item.id ? 0 : 1;
          });
        }
      });
    },
    // 删除
    del(item) {
      Dialog.confirm({
        title: `确认删除【${item.name}】么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm' && !this.deleting) {
            this.deleting = true;
            deleteAddress({ id: item.id }).then(res => {
              this.deleting = false;
              if (res === true) {
                this.onRefresh();
                this.$store.commit('payment/setAddressId');
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
    add() {
      this.$router.push({ path: '/addAddress' });
    },
    edit(item) {
      this.$router.push({
        path: '/editAddress',
        query: { addressId: item.id },
      });
    },

    handleAddressClick(obj) {
      if (this.$route.query.model === 'check') {
        this.$store.commit('payment/setAddressId', obj.id);
        this.$router.back();
      }
    },
    isDefaultAddress(obj) {
      return obj.defaultAddress === 0;
    },
    formatAddressText(obj) {
      let addressKeys = ['provice', 'city', 'country', 'street', 'address'];
      let addressArr = [];
      addressKeys.forEach(key => {
        let itemStr = obj[key];
        itemStr && addressArr.push(itemStr);
      });
      return addressArr.join(' ');
    },
  },
};
</script>

<style lang="less" scoped>
.address-list-container {
  padding: 0 0 80px 0;
}

.address-item {
  box-sizing: border-box;
  margin: 10px;
  padding: 10px 10px 0 10px;
  border-radius: 15px;
  background-color: #fff;

  .address-info-container {
    position: relative;
    z-index: 1;
    padding-right: 40px;

    .user-info-container {
      display: flex;

      .user-info {
        flex: 1;
        line-height: 24px;
        font-size: 16px;
        color: #333;
        font-weight: bolder;

        & > span {
          display: inline-block;
          font-size: 14px;
          color: #666;
          font-weight: normal;
        }
      }

      .default-tag {
        flex-shrink: 0;
        width: 40px;

        .van-tag {
          margin-top: 2px;
          line-height: 18px;
        }
      }
    }

    .user-address-info {
      margin-top: 10px;
      line-height: 22px;
      font-size: 16px;
      color: #333;
    }

    .edit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      width: 40px;

      .van-icon {
        font-size: 22px;
        color: #999;
      }
    }
  }

  .bottom-btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 8px 0 8px 0;
    height: 30px;
    border-top: 1px solid #f1f1f1;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      color: #999;
    }

    .van-icon {
      margin-right: 8px;
      font-size: 20px;

      &.van-icon-passed {
        color: #666;
      }
    }

    .is-default {
      .van-icon {
        color: @primary-color;
      }
    }

    .del-btn {
      width: 40px;
    }


  }
}

.add-btn-container {
  position: fixed;
  z-index: 1;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  padding: 10px 20px 20px 20px;
  width: 100%;
  background-color: #fff;
}
</style>
